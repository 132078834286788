@use './common'

.Wrapper
	.Item + .Item
		margin-top: 40px

	&.perLine_2
		@media(min-width: common.$break480)
			display: grid
			grid-gap: 20px
			grid-template-columns: repeat(2, 1fr)
			.Item + .Item
				margin-top: 0

	&.perLine_4
		@media(min-width: common.$break480)
			display: grid
			grid-gap: 20px
			grid-template-columns: repeat(2, 1fr)
			.Item + .Item
				margin-top: 0
		@media(min-width: common.$break700)
			grid-template-columns: repeat(3, 1fr)
		@media(min-width: common.$break1000)
			grid-template-columns: repeat(4, 1fr)

.viewSlide
	@media(max-width: common.$break480)
		padding-bottom: 30px
		+common.horizontallyScrollableWithoutScrollbarOnSmallTouchDevice

		// @TODO: show arrows to scroll to next on large screen
		+common.hideScrollbar
		display: flex
		.Item
			width: 65%
			flex-shrink: 0
			margin-top: 0!important
			margin-left: 20px
		.Item:last-child
			margin-right: 20px

.hide
	display: none

.loadMore
	text-align: center
	margin: 40px 0 20px 0
