@use 'common'

$font-base: 22px

@function em($fontSize, $baseFontSize: 16)
	@return common.em($fontSize, $font-base)

.Container
	font-family: common.$f-montreal
	font-size: var(--Textual-font-size, clamp(14px, .2em + 6vw, var(--Textual-font-size-base, #{$font-base})))
	line-height: var(--Textual-line-height, common.div(28px, $font-base))
	color: var(--Textual-color, #{common.$c-dark})

	.Container
		// prevent double upsizing
		font-size: 1em

	h1, h2, h3, h4, h5, h6, p, ul, ol, li, blockquote
		font-size: 1em
		margin: 0
		margin-bottom: calc(var(--Textual-spacing-coef, 1) * .72em)

	li
		margin-bottom: calc(var(--Textual-spacing-coef, 1) * .33em)

	h1
		// not used in RichText

	h1, h2
		font-size: em(30px)

	h3
		font-size: em(27px)

	h4
		font-size: em(25px)

	h5
		font-size: em(23px)

	h5
		font-size: em(22px)

	p
		margin-bottom: calc(var(--Textual-spacing-coef, 1) * 1.5em)

	hr
		display: block
		border: none
		border-top: solid 1px
		margin: 2em 0
		opacity: .1
