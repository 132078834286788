@use 'common'

$horizontalItemSpacing: var(--global-page-horizontal-spacing)
$GradientColor: #fff, hsla(0, 0%, 100%, .7), hsla(0, 0%, 100%, .4), hsla(0, 0%, 100%, 0)

.wrapper
	width: 100%
	display: grid
	position: relative

	&::before, &::after
		content: ''
		width: $horizontalItemSpacing
		position: absolute
		top: 0
		bottom: 0
		z-index: 1

	&::before
		left: calc(-1 * $horizontalItemSpacing)
		background: linear-gradient(90deg, $GradientColor)

	&::after
		background: linear-gradient(270deg, $GradientColor)
		right: calc(-1 * $horizontalItemSpacing)

.chunk
	+common.horizontallyScrollableWithoutScrollbarOnSmallTouchDevice
	display: flex
	margin: 0 calc(-1 * $horizontalItemSpacing)
	scroll-snap-type: x proximity
	scroll-behavior: smooth

	&:not(:first-child)
		margin-top: 30px

.item
	display: flex
	flex-shrink: 0
	scroll-snap-align: start
	width: 80%
	padding-right: $horizontalItemSpacing
	padding-left: $horizontalItemSpacing

	@media (min-width: common.$break560)
		width: 45%
