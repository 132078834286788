@use 'common'

$size: 10em

.wrapper
	position: absolute
	top: var(--PlacedSticker-top)
	left: var(--PlacedSticker-left)
	width: 0
	height: 0
	pointer-events: none

	.binaryPoll
		width: $size
		height: $size
		margin-top: -0.5 * $size
		margin-left: -0.5 * $size
		display: grid
		align-items: center

		// Zkusíme jestli to zvládají pozicovat bez problémů
		// @media (max-width: common.$breakBinaryPollVisibilityMax)
		// 	display: none

	.sticker
		//
