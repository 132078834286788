@use 'common'

.Element
	display: inline
	position: relative
	color: var(--HighlightColor-fillColor, currentColor)

	&.hasBackground
		$side: .16em
		$dy: 0
		line-height: 1.08
		box-decoration-break: clone
		-webkit-box-decoration-break: clone
		padding: 4px 12px
		background-color: var(--HighlightColor-backgroundColor, transparent)
		box-shadow: -1 * $side 0 0 var(--HighlightColor-backgroundColor, transparent), $side 0 0 0 var(--HighlightColor-backgroundColor, transparent), -1 * $side $dy 0 var(--HighlightColor-backgroundColor, transparent), $side $dy 0 0 var(--HighlightColor-backgroundColor, transparent)

	> .Text
		z-index: 1
