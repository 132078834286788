@use 'common'
@use 'sass:math'

$ripple-duration: 0.4s
$split-shift-duration: 1s
$spread-duration: 0.5s

$max-width: common.rem(150)

.wrapper::after,
.background::before
	content: ''
	background-image: linear-gradient(to right, var(--BinaryPoll-sideA-backgroundColor, common.$c-dark) 50%, var(--BinaryPoll-sideB-backgroundColor, common.$c-dark) 50%)

.wrapper
	margin: 0 auto
	max-width: $max-width
	width: 100%
	position: relative
	z-index: 0
	text-align: center
	color: var(--BinaryPoll-textColor, inherit)

	&::before
		content: ''
		display: block
		padding-top: 100% // Square

	&::after
		z-index: -1
		border-radius: 50%
		transition-property: opacity, visibility, transform
		transition-duration: $ripple-duration

	&.is_results::after,
	&.is_voted_A::after,
	&.is_voted_B::after
		opacity: 0
		transform: scale(1.3)
		visibility: hidden

	&.is_voted_A::after
		clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%)

	&.is_voted_B::after
		clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%)

.wrapper::after,
.in,
.background,
.background::before,
.background::after,
.answerButtons
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0

.background::after
	// Makes draggable circle
	content: ''
	pointer-events: auto
	border-radius: 50%

.background
	border-radius: 50%
	overflow: hidden
	transform: translateZ(0) // Fix Safari not overflow: hidden while animating

	&::before
		transform-origin: 100% 0
		transition: transform $split-shift-duration math.div($ripple-duration, 2)
		left: -50%
		right: -50%
		transform: translateX(calc((var(--BinaryPoll-ratio) - 0.5) * 50%))

.answerButtons
	z-index: 1

.answerButton
	position: absolute
	background-color: rgba(#ffffff, 0.2)
	border: none
	opacity: 0
	transition: opacity 0.3s
	pointer-events: auto
	top: 0
	bottom: 0
	height: 100%
	width: 50%

	&:hover,
	&:focus
		opacity: 1

	&:first-child
		left: 0
		border-top-left-radius: $max-width
		border-bottom-left-radius: $max-width

	&:last-child
		right: 0
		border-top-right-radius: $max-width
		border-bottom-right-radius: $max-width

.in
	display: flex
	flex-direction: column

	&::before,
	&::after
		content: ''
		flex-grow: 1

.question
	$font-size: 20

	font-size: common.em($font-size)
	grid-row: 2

	transition-property: transform
	transition-duration: $spread-duration
	transition-delay: $ripple-duration
	transform: translateY(0.3em)

	.is_results &
		transform: none

	&Icon,
	&Image
		display: flex
		margin: 0 auto

	&Icon
		max-width: common.em(16, $font-size)

	&Image
		max-width: common.em(100, $font-size)

.answers
	grid-row: 3
	display: grid
	grid-template-columns: 1fr 1fr
	text-align: center
	font-weight: 700
	line-height: 1
	margin: 0.1em 0.3em 0

.answer
	&Score
		overflow: hidden
		font-size: common.em(22)

		.is_sideA &
			--BinaryPoll-side: -1

		.is_sideB &
			--BinaryPoll-side: 1

		&In
			transition-property: transform, opacity, visibility
			transition-duration: $spread-duration
			transition-delay: $ripple-duration + math.div($split-shift-duration, 2)
			transform: translateX(calc(var(--BinaryPoll-side) * 100%))

			.is_results &
				transform: none

	&Image,
	&Text
		font-size: common.em(14)
		transition-property: transform
		transition-duration: $spread-duration
		transition-delay: $ripple-duration
		transform: translateY(-1em)

		.is_results &
			transform: none

	&Image
		position: relative
		margin: 0.2em auto 0
		width: common.rem(44)
		height: common.rem(44)

	&Text
		//
