@use 'common'

$size-small: 2em
$size-medium: 4em

$spacing: .75em

.wrapper
	--Sticker-size: #{$size-small}
	display: inline-block
	pointer-events: auto

	&.size_small
		--Sticker-size: #{$size-small}

	&.size_medium
		--Sticker-size: #{$size-medium}

.sticker
	display: inline-block
	position: relative
	margin-left: calc(-0.5 * var(--Sticker-size))
	margin-top: calc(-0.5 * var(--Sticker-size))
	padding: $spacing
	width: var(--Sticker-size)
	height: var(--Sticker-size)

	&::before
		display: block
		content: ''
		position: absolute
		top: -1 * $spacing
		left: -1 * $spacing
		right: -1 * $spacing
		bottom: -1 * $spacing
		background: var(--Sticker-backgroundColor)
		border-radius: var(--Sticker-size)

.link
	//
