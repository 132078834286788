@use './common'

.wrapper
	//

.list
	padding: 0
	list-style: none

.listItem
	display: flex
	background-color: #fff
	padding: 10px 15px 10px 10px
	border-radius: 10px
	align-items: center

.listItem + .listItem
	margin-top: 10px

.image
	position: relative
	width: 48px
	height: 48px
	border-radius: 10px
	overflow: hidden

.partnerTitle
	padding: 0
	margin: 0
	font-size: 16px
	color: common.$c-dark
	font-family: common.$f-epilogue

.saleText
	padding: 0
	margin: 5px 0 0 0
	font-family: common.$f-epilogue
	font-size: 14px
	color: common.$c-grey

.text
	margin: 0 10px

.code
	margin-left: auto
	justify-self: flex-end
	display: flex
	align-items: center

.codeText
	padding: 0
	margin-right: 10px
	color: common.$c-dark
	font-family: common.$f-epilogue
	font-size: 16px

.codeButton
	background-color: transparent
	border: 1px solid #000
	width: 16px
	height: 16px
	position: relative
	transform: translateY(-3px)
	&::after
		content: ''
		position: absolute
		top: 2px
		left: 2px
		border: 1px solid #000
		width: 16px
		height: 16px
		background-color: #fff
	&.copied
		border: none
		border-left: 1px solid #000
		border-bottom: 1px solid #000
		transform: translateY(-6px) rotate(-45deg)
		height: 8px
	&.copied::after
		display: none
