@use 'common'

.wrapper
	display: flex
	align-items: center

.avatar
	position: relative
	width: common.em(40)
	flex-shrink: 0
	background-color: currentColor
	border-radius: 50%
	margin-right: 0.5em
	padding: common.rem(2)

	&In
		position: relative
		border-radius: 50%
		overflow: hidden
		padding-top: 100%

.name
	font-weight: 700
	text-shadow: 0 0 0.5rem rgba(#000000, 0.3)
