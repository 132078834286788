@use './common'

.wrapper
	display: block
	padding: 2px
	border: 1px solid common.$c-light-grey
	border-radius: 50%
	width: 44px
	height: 44px

.image
	position: relative
	border-radius: 50%
	overflow: hidden
	width: 100%
	height: 100%
