@use './common'

.wrapper
	border-left: 2px solid var(--Quote-color)
	padding: 0 0 0 15px
	margin: 0

	&.colorless
		padding: 0

.primary, .secondary
	font-weight: 400
	color: common.$c-dark
	margin: 0
	padding: 0
	p
		margin: 0

.primary
	font-size: 28px
	margin-bottom: 5px

.secondary
	font-size: 16px

@media(min-width: common.$break700)
	.wrapper
		padding: 0
		border: none
		position: relative
		&:not(.colorless)
			&:before
				content: ''
				height: 100%
				border-left: 2px solid var(--Quote-color)
				position: absolute
				top: 0
				left: -25px
