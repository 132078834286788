@use './common'

$break: common.$break1000

.wrapper
	--PartnerGrid-spacing: 15px
	display: flex
	align-items: center
	flex-wrap: wrap

	@media (min-width: $break)
		--PartnerGrid-spacing: 25px

.title
	display: none

.title,
.partner
	&:not(:last-child)
		margin-right: var(--PartnerGrid-spacing)

.partner
	&:hover,
	&:focus
		transform: scale(1.1)

.dark
	.title
		color: #000

@media (min-width: $break)
	.title
		font-family: common.$f-epilogue
		font-weight: 400
		padding: 0
		margin-top: 0
		margin-bottom: 0
		color: #fff
		font-size: 14px
		display: inline-block
