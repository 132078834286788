@use 'common'

$spacing: 2em

.wrapper
	width: 100%
	text-align: center

	@media (min-width: common.$break700)
		&.left,
		&.right
			margin-bottom: 0.5 * $spacing
			max-width: 45%

		&.left
			clear: left
			float: left
			margin-right: $spacing
			text-align: right

		&.right
			clear: right
			float: right
			margin-left: $spacing
			text-align: left
