@use 'common'

.wrapper
	pointer-events: none
	touch-action: none

	*
		user-select: none

.in
	transform: translate(var(--Draggable-offset-x), var(--Draggable-offset-y))
