@use 'common'

.view
	&Mobile

		@media (min-width: common.$break1000)
			display: none

	&Desktop

		.main
			display: grid
			grid-template-columns: repeat(4, minmax(0, 1fr))
			gap: 30px

		@media (max-width: common.$break1000 - 1px)
			display: none
