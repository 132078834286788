@use './common'

.wrapper
	width: 100%
	display: flex
	align-items: flex-start

.image
	position: relative
	width: 20px
	height: 20px
	border-radius: 50%
	overflow: hidden
	flex-shrink: 0
	margin-right: 5px
	margin-top: 5px

.content
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	align-items: flex-start
	width: 100%

.actions
	display: flex
	padding-left: 12px

.time
	font-size: 13px
	color: common.$c-grey
	margin-right: 16px

.respondButton
	color: #000
	padding: 0
	background-color: transparent
	border: none
	font-size: 13px
	font-weight: 700
	cursor: pointer

.reportButton
	color: #7e7e7e
	padding: 0
	background-color: transparent
	border: none
	font-size: 13px
	cursor: pointer
	margin-left: 5px

.like
	display: flex
	align-items: center
	background-color: transparent
	border: none
	padding: 0
	cursor: pointer

.likeCount
	font-size: 11px
	font-weight: 500
	color: common.$c-dark
	margin-left: 4px

.text
	padding: 12px
	border-radius: 15px
	font-weight: 400
	color: #2e2e2e
	width: auto
	margin-bottom: 5px

.bottom
	width: 100%
	display: flex
	justify-content: space-between
