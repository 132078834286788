@use 'common'

.wrapper
	position: relative
	background-color: currentColor
	border-radius: 50%
	clip-path: polygon(33% 0, 100% 0, 100% 100%, 0 100%, 0 0, 33% 0, 33% 20%, 33% 80%, 82% 50%, 33% 20%)

	&::before
		content: ''
		display: block
		padding-top: 100%
