@use "common"

$verticalSpacing: 10px

.wrapper
	width: 100%
	position: relative
	display: flex
	flex-direction: column
	padding: 10px
	border: 2px solid common.$c-grey
	border-radius: 20px
	overflow: hidden
	z-index: 0

.image
	$aspectRatio: common.div(1, 1)

	position: relative
	padding-top: common.div(100%, $aspectRatio)
	border-radius: 10px
	overflow: hidden
	z-index: -1

	&In
		position: absolute

		transition: common.$image-transform-transition
		top: 0
		right: 0
		bottom: 0
		left: 0

	&::after
		content: ''
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		visibility: hidden
		transition-property: visibility, background-color
		transition-duration: .3s

.title
	margin: 0
	margin-top: 15px
	font-size: 20px

.price
	margin: 0
	margin-top: 5px
	font-size: 16px
	font-family: common.$f-montreal
	font-weight: 700

.author
	align-self: flex-start
	margin-top: $verticalSpacing

.button
	height: 100%
	display: flex
	flex-direction: column
	justify-content: flex-end
	margin-top: $verticalSpacing
	order: 1 // last order

	&In
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0

	&:hover, &:focus
		outline: 0

		& ~ .image
			&::after
				visibility: inherit
				background-color: rgba(#000000, .3)

			.imageIn
				transform: common.$image-transform-scale
