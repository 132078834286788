@use 'common'

.wrapper
	width: 100%
	background-color: common.$c-beige
	padding: 50px 0

.showall
	.hide
		display: block

.item + .item
	margin-top: 15px

.isReply, .showRepliesButton
	margin-left: 37px

.parentPost + .parentPost
	margin-top: 15px

.parentPostHideReplies
	.isReply + .isReply
		display: none

.repliesVisible
	.showRepliesButton
		display: none
	.isReply + .isReply
		display: block

.hide
	display: none

.head
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 30px
	&.noPosts
		margin-bottom: 0
	@media (max-width: common.$break768)
		flex-wrap: wrap
		justify-content: center
		flex-direction: column

.heading
	margin: 0
	text-transform: uppercase
	font-weight: 900
	color: common.$c-dark
	font-size: 30px

.button
	padding: 13px 20px
	color: #fff
	background-color: common.$c-dark
	border-radius: 22px
	display: inline-block
	font-size: 14px
	font-weight: 700
	text-transform: uppercase
	cursor: pointer
	text-decoration: none
	span
		color: #000
		font-weight: 500
		background-color: #fff
		line-height: 1
		padding: 3px 10px
		border-radius: 50%
		font-size: 14px
		margin-left: 20px
		position: relative
		&::after
			content: ''
			position: absolute
			bottom: -2px
			left: -2px
			border-bottom: 3px solid transparent
			border-left: 0
			border-right: 8px solid #fff
			border-top: 3px solid transparent
			transform: rotate(-45deg)
	@media (max-width: common.$break768)
		font-size: 12px
		margin-top: 10px
		flex-shrink: 0

.showAll, .showRepliesButton
	border: none
	padding: 0
	background-color: transparent
	font-size: 13px
	color: common.$c-grey
	margin-top: 30px
	font-weight: 700
