@use './common'

=extraImages
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: red
	display: flex
	align-items: center
	justify-content: center
	background-color: rgba(46, 46, 46, 0.69)
	font-size: 40px
	color: #fff

.wrapper
	width: 100%

.inner
	display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-gap: 10px
.imageWrapper:nth-child(7), .imageWrapper:nth-child(8)
	display: none
.imageWrapper
	position: relative
	width: 100%
	padding: 0
	padding-top: 100%
	border-radius: 20px
	transform: translateZ(0) // fixes border radius while zooming in Safari
	overflow: hidden
	border: none
	background-color: transparent
	cursor: pointer
	svg
		z-index: 1
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		width: 61px
		height: 61px
	&:hover
		.image
			transform: scale(1.05)
.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	transition: common.$image-transform-transition

.overSix
	.imageWrapper:nth-child(6):after
		counter-reset: extraImages calc(var(--Gallery-extra-images) + 2)
		content: '+' counter(extraImages)
		+extraImages

@media(min-width: common.$break700)
	.inner
		grid-template-columns: repeat(4, 1fr)
	.imageWrapper:nth-child(7), .imageWrapper:nth-child(8)
		display: block
	.overSix
		.imageWrapper:nth-child(6):after
			display: none
	.overEight
		.imageWrapper:nth-child(8):after
			counter-reset: extraImages var(--Gallery-extra-images)
			content: '+' counter(extraImages)
			+extraImages
