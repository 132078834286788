@use './common'

=large
	position: relative
	display: block
	padding: 0
	background-color: transparent
	border-radius: 0

	.ImageWithOverlays
		margin: 0 0 14px 0
		width: 100%
		aspect-ratio: 1/1
		overflow: hidden
		border-radius: 20px
		max-width: 100%

	.ImageWrapper
		width: 100%

		&::after
			padding-top: 100%

	.Content
		.Heading
			font-size: 25px
	&.portrait
		.ImageWithOverlays
			width: 100%

		.ImageWrapper
			width: 100%

			&::after
				padding-top: 125%

	.Stickers
		display: block
		pointer-events: none
		z-index: 3
		position: absolute
		top: 0
		left: 0
		bottom: 0
		right: 0

.Wrapper
	display: flex
	flex-direction: row-reverse
	background-color: common.$c-beige
	padding: 16px
	border-radius: 20px
	align-items: flex-start
	position: relative

	.Link
		&::before
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 1

		&:focus, &:hover
			~ .ImageWithOverlays .ImageWrapper .Image
				transform: common.$image-transform-scale

			~ .Content .Heading
				color: hsl(0, 0%, 35%)
			~ .Content .HeadingHiglighted
				color: common.$c-white

	.ImageCrop
		overflow: hidden
		border-radius: 20px
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: common.$image-background
		transform: translateZ(0) // fixes border radius while zooming in Safari

	.ImageWrapper
		position: relative
		transform: translateZ(0) // fixes border radius while zooming in Safari

		&::after
			width: 100%
			padding-top: 100%
			display: block
			content: ''

		.Image
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			transition: common.$image-transform-transition

	.Tags
		margin-bottom: 7px
		z-index: 2
		position: relative

	.Content
		width: 100%

		.Heading
			margin: 0.5rem 0 1rem 0
			font-size: 20px
			font-weight: 500
			transition: color .3s
		.HeadingHiglighted span
			text-transform: uppercase
			box-decoration-break: clone
			-webkit-box-decoration-break: clone
			padding: 4px 12px
			color: common.$c-white
			background-color: common.$c-purple
			font-size: 1.4rem
			font-weight: 700

		.Description
			font-size: 14px
			font-family: common.$f-epilogue
			color: common.$c-dark
			opacity: .7
			margin-bottom: 10px
			line-height: 1.3

	&.large
		+large

	@media(min-width: common.$break480)
		&:not(.small)
			+large

		&.small
			.Content
				.Heading
					font-size: 25px

		&.smallTitle
			.Content
				.Heading
					font-size: 19px

.Stickers
	display: none

.ImageWithOverlays
	position: relative
	max-width: 150px
	margin: 0 0 0 18px
	width: 35%
	flex-shrink: 0

@media(max-width: common.$break480)
	.Wrapper
		&:not(.large)
			flex-wrap: wrap
			flex-direction: row

			.ImageWithOverlays
				order: 3

			.Tags
				width: 100%
				order: 1

			.Content
				width: calc(65% - 18px)
				order: 2
