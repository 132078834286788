@use "common"

.productOfTheMonthTitleWrapper
	display: flex
	justify-content: center
	align-items: center

.imageHead
	width: 1.5rem
	aspect-ratio: 1/1
	@media ( min-width: common.$break480 )
		width: 2.1rem

.productOfTheMonthTitle
	padding: 0 0.5rem
	font-weight: 700
	text-align: center
	font-size: 1.1rem
	@media ( min-width: common.$break480 )
		font-size: 1.5625rem
	@media ( min-width: common.$break768 )
		font-size: 1.875rem

.wrapper
	background-color: #ecf6fd
	border: 1px solid #000
	border-radius: 2rem
	@media ( min-width: common.$break480 )
		display: grid
		gap: 0.3rem
		align-items: center
		grid-template-columns: 0.5fr 1fr
	@media ( min-width: common.$break1100 )
		justify-content: flex-start
		display: flex
		gap: 0.5rem
		justify-content: space-between

.image
	overflow: hidden
	margin: auto
	width: 75%
	margin: 0.5rem auto 0
	@media ( min-width: common.$break480 )
		width: 100%
	@media ( min-width: common.$break1100 )
		width: 20%
		margin-left: 0

.imageSecond
	display: none
	@media ( min-width: common.$break1100 )
		display: flex

.contentIn
	display: flex
	flex-direction: column
	align-items: flex-start
	padding: 1.3rem 1rem 1rem 1rem
	@media ( min-width: common.$break768 )
		padding: 1rem
	@media ( min-width: common.$break1100 )
		align-items: center

.sponsored
	background-color: #85dbd9
	border-radius: 1.25rem
	width: fit-content
	padding: 0.25rem 1rem
	font-size: 0.75rem
	@media ( min-width: common.$break768 )
		padding: 0.5rem 2rem
		font-size: 1rem

.productText
	font-size: 1rem
	@media ( min-width: common.$break480 )
		font-size: 1.125rem
	@media ( min-width: common.$break768 )
		font-family: common.$f-montreal
		font-size: 1.4rem
	@media ( min-width: common.$break1100 )
		text-align: center

.productName
	font-size: 1.2rem
	font-weight: 700
	margin-block: 0.5rem
	@media (min-width: common.$break768)
		font-size: 1.5rem
		margin-top: 1.5rem
	@media ( min-width: common.$break1100 )
		font-size: 2.3rem
		text-align: center

.productButton
	margin-top: 1rem

.articleLocated
	.productName, .productText
		text-align: left
	.contentIn
		display: flex
		flex-direction: column
		align-items: flex-start
	.imageSecond
		display: none
	.productName
		font-size: 1.8rem
	.image
		@media ( min-width: common.$break1100 )
			width: 100%

.articleLocated .wrapper
	@media ( min-width: common.$break1100 )
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 0.5rem
