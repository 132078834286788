@use './common'

.wrapper
	display: flex
	align-items: center
	position: relative
	z-index: 2
	text-decoration: none
	color: common.$c-grey
	font-family: common.$f-epilogue
	transition: color .3s

	.image
		width: 2.4rem
		height: 2.4rem
		border-radius: 12px
		transform: translateZ(0) // fixes border radius while zooming in Safari
		overflow: hidden
		margin-right: common.em(10)
		background: common.$image-background

	.label
		font-size: common.em(12)
		text-decoration: none
		color: var(--AuthorBadge-label-color, common.$c-grey)
		font-family: common.$f-epilogue

	&:hover, &:focus
		outline: none

	&.view_link
		.image
			position: relative

			&In
				position: absolute
				top: 0
				right: 0
				bottom: 0
				left: 0
				transition: common.$image-transform-transition

			&::after
				content: ''
				position: absolute
				top: 0
				right: 0
				bottom: 0
				left: 0
				visibility: hidden
				transition-property: visibility, background-color
				transition-duration: .3s

		.name
			text-decoration: underline

		&:hover, &:focus
			.name
				text-decoration: none

			.image
				&In
					transform: scale(1.1)

				&::after
					background-color: rgba(#000000, .3)
					visibility: inherit
