@use 'common'

.Wrapper
	max-width: 100%
	max-width: var(--Embed-max-width)

.Iframe
	overflow: hidden
	padding-top: calc(100% / var(--Embed-aspect-ratio))
	position: relative
	iframe
		max-width: 100%
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%

.twitter
	iframe
		max-width: calc(100% + 15px) //hide iframes scrollbar
		width: calc(100% + 15px) //hide iframes scrollbar
