@use 'common'

$arrow-x-offset: 56%

.wrapper
	--Arrow-offset-multiplier: 1
	position: relative
	width: common.em(30)
	height: common.em(30)
	border: none
	background-color: #ffffff
	color: #2f2f2f
	border-radius: 50%
	pointer-events: auto
	box-shadow: 0 0 10px rgba(#000000, 0.3)
	font-size: inherit

	&.view_direction_previous
		--Arrow-offset-direction: -1

	&.view_direction_next
		--Arrow-offset-direction: 1

	&::before
		content: ''
		position: absolute
		top: 50%
		left: 50%
		width: common.em(11)
		height: common.em(11)
		border-top: common.em(4) solid
		border-left: common.em(4) solid
		transform-origin: 0 0
		transform: translateX(calc(var(--Arrow-offset-multiplier) * var(--Arrow-offset-direction) * #{$arrow-x-offset})) rotate(calc(45deg + 90deg * var(--Arrow-offset-direction)))
		transition-property: transform, color
		transition-duration: 0.2s

	// ::after increases clickable area
	&::after
		$offset: -80%

		content: ''
		position: absolute
		top: $offset
		bottom: $offset
		left: $offset
		right: $offset

	&:hover,
	&:focus
		--Arrow-offset-multiplier: 1.2

		&::before
			color: #000000
