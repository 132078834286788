@use './common'

.wrapper
	--Gradient-color-start: red
	--Gradient-color-middle: yellow
	--Gradient-color-end: blue
	--Gradient-angle: 270deg
	--Gradient-hint: 20%

	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-image: linear-gradient(var(--Gradient-angle), var(--Gradient-color-start), var(--Gradient-color-middle) var(--Gradient-hint), var(--Gradient-color-end))
