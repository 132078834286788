@use 'common'

$gap: 1em
$vertical-gap: common.rem(50)
$height: min(80vh, 480px)
$aspect-ratio: common.div(9, 16)
$break: common.$break1000

.Container
	position: relative
	z-index: 0
	width: 100%
	overflow: hidden
	padding-top: $vertical-gap

.Head
	display: flex
	justify-content: space-between
	align-items: center
	flex-wrap: wrap
	margin-bottom: 30px

.Title
	margin: 0

.TitleLink
	flex-shrink: 0
	text-decoration: none
	color: #fff
	font-size: 50px
	font-weight: 900
	text-transform: uppercase

	&:hover,
	&:focus
		text-decoration: underline

.Partners
	flex-shrink: 0

.Items
	position: relative

	&Carousel
		+common.horizontallyScrollableWithoutScrollbarOnSmallTouchDevice
		+common.hideScrollbar

		display: flex
		padding-left: env(safe-area-inset-right)

		&::before,
		&::after
			content: ''
			width: calc((100% - var(--global-content-width-normal)) / 2 - var(--global-page-horizontal-spacing))
			flex-shrink: 0

	&In
		display: flex
		padding: 0 calc(var(--global-page-horizontal-spacing) - #{0.5 * $gap}) $vertical-gap

.Catch
	//

.Controls
	position: absolute
	top: 0
	left: 0
	right: 0
	padding: 0 var(--global-page-horizontal-spacing)
	height: $height
	pointer-events: none
	display: none
	justify-content: space-between
	align-items: center

	@media (min-width: $break)
		display: flex

	&Previous,
	&Next
		pointer-events: auto
		transition-property: opacity, visibility
		transition-duration: 0.2s
		font-size: 1.2em
		display: flex

	.is_startVisible &Previous,
	.is_endVisible &Next
		opacity: 0
		visibility: hidden

.Item
	position: relative
	z-index: 0
	margin: 0 common.div($gap, 2)
	flex-grow: 0
	flex-shrink: 0
	width: calc($height * $aspect-ratio)
	height: $height
	color: #ffffff
	border-radius: common.em(20)
	overflow: hidden

	&:hover
		.Play
			transform: scale(0.9)

	&::before
		content: ''
		position: absolute
		bottom: 0
		left: 0
		right: 0
		top: 50%
		opacity: 0.7
		background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%) // https://css-tricks.com/easing-linear-gradients/#postcss-to-the-rescue

	&In
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		padding: 1rem
		display: flex
		flex-direction: column
		text-decoration: none
		overflow: hidden

	.Thumbnail
		height: 100%
		width: 100%
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		object-fit: cover

	.Author
		font-size: 0.8em
		flex-grow: 1

	.Title
		margin: 0
		font-size: inherit
		line-height: 1.1
		gap: 0.7em
		display: grid
		grid-template-columns: 2.7em 1fr
		align-items: center

	.Video
		$a: calc(var(--FomoFeed-item-width) / var(--FomoFeed-item-height))
		$h: calc(max($height, calc(#{$height} * #{$aspect-ratio} / #{$a})))
		$w: calc(#{$h} * #{$a})

		position: absolute
		top: calc(50% - #{$h} / 2)
		left: calc(50% - #{$w} / 2)
		width: $w
		height: $h
		opacity: 0
		transition-property: opacity
		transition-duration: 0.2s
		display: grid

		&.is_playable
			opacity: 1

	.Preview
		position: absolute
		z-index: -1
		top: 0
		left: 0
		bottom: 0
		right: 0
		transition: common.$image-transform-transition

	.Play
		transition: transform .35s ease

	&.is_hovered
		.Preview
			transform: common.$image-transform-scale
