@use 'common'

.Element
	display: inline

	color: var(--ThreeColor-fillColor)
	text-shadow: .09em .09em 0 var(--ThreeColor-shadowColor)
	-webkit-text-stroke: .02em var(--ThreeColor-outlineColor)

	> .Text
		z-index: 1
