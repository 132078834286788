.wrapper
	--Background-color: transparent
	background-color: var(--Background-color)
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: -1
	pointer-events: none

	&.view_fixed
		position: fixed
