@use 'common'

$values-font-size: common.rem(14)
$values-min-height: common.rem(32)

.wrapper
	$layout: 1fr 1px 1fr

	border-radius: 1.25em
	padding: 1.75em
	border: 1px solid common.$c-dark
	background-color: common.$c-blue-light
	display: grid
	grid-template-rows: $layout
	gap: 2em
	text-align: center
	font-family: common.$f-epilogue

	position: relative

	&.locked
		$layout: 1fr
		padding: 0 1.75em
		background-color: transparent
		border: none

	@media (min-width: common.$break700)
		grid-template-rows: none
		grid-template-columns: $layout

	&::before
		content: ''
		background-color: common.$c-dark

	&.locked::before
		display: none

.info,
.form
	display: grid
	align-items: center

.info
	order: -1 // First
	line-height: 1.2

	&In
		//

	&Title
		font-size: 1.125em
		margin: 0.8em 0 0
		font-weight: 400
		font-family: inherit

	&Text
		margin: 0.7em 0 0
		font-size: 0.875em

.form
	position: relative

	&In
		//

	&Title
		font-size: 1.125em
		font-family: inherit
		margin: 0
		font-weight: 400

	&Picker
		$padding: 0.625em

		margin-top: 0.8em
		background-color: #fdf2f6
		border-radius: $padding
		padding: $padding
		display: flex
		align-items: center
		justify-content: center

	&Fieldset
		border: none
		padding: 0
		margin: 0
		display: block
		&[disabled]
			opacity: .5

		&.paymentUnavailable
			opacity: .2
			filter: blur(6px)

		&.working
			filter: blur(6px)

.predefinedAmount
	$font-size: $values-font-size
	$min-height: $values-min-height
	$padding: common.div($min-height - $font-size, 2)
	$top-padding-multiplier: 1.1 // To fix vertical alignment
	$border-width: 1px

	line-height: 1
	border-radius: common.div($min-height, 2)
	min-width: $min-height
	font-size: $font-size
	color: common.$c-orange
	border: $border-width solid
	background-color: #ffffff
	text-align: center
	line-height: 1
	padding: calc(#{$padding * $top-padding-multiplier} - #{$border-width}) (0.5 * $padding) calc(#{$padding - (($padding * $top-padding-multiplier) - $padding)} - #{$border-width})
	margin-left: 0.5em

	&.is_active
		background-color: common.$c-orange
		color: #ffffff
		border-color: common.$c-orange

.thingIcon
	display: flex
	align-items: center
	flex-shrink: 0
	max-width: 6em

	&::after
		content: '×'
		font-size: 1.5em
		margin: 0 0.3em -0.1em
		line-height: 1

.customValue
	border-radius: common.rem(5)
	border: 1px solid #f2f2f2
	background-color: #ffffff
	font-size: $values-font-size
	height: $values-min-height
	width: $values-min-height
	text-align: center
	-moz-appearance: textfield

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		-webkit-appearance: none
		margin: 0

	&:focus
		outline: none
		border-color: common.$c-orange
		box-shadow: 0 0 0.4rem rgba(common.$c-orange, 0.5)

.submit
	margin-top: 0.5em

.author
	text-decoration: none

	&Avatar
		$size: common.em(88)

		position: relative
		border-radius: 50%
		width: $size
		height: $size
		margin: 0 auto
		overflow: hidden

	&Name
		margin: 0
		font-size: common.em(18)
		font-weight: 400
		font-family: inherit

		&:not(:first-child)
			margin-top: 0.8em

	&:hover &,
	&:focus &
		&Name
			text-decoration: underline

	&Description
		margin: 0
		font-size: common.em(14)

		&:not(:first-child)
			margin-top: 0.6em

.providers
	margin-top: 0.7em
	display: flex
	align-items: center
	justify-content: center
	flex-wrap: wrap

	&Note
		font-size: common.em(14)
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		text-align: center
		line-height: 1.3

	&Label
		font-size: common.em(12)
		color: #2e2e2e
		margin-right: 1.5em

	&Items
		display: flex

.provider
	$gap: 0.6rem

	display: inline-flex
	position: relative
	border: none
	padding: 0
	background-color: transparent
	flex-shrink: 0
	font-size: 2rem
	line-height: 1
	margin: 0

	opacity: .25

	&.available
		opacity: 1

	&::before
		content: ''
		position: absolute
		top: -$gap
		bottom: -$gap
		left: -$gap
		right: -$gap

	&Icon
		display: flex
		margin: -0.3rem 0
		transition: transform ease-in-out .2s
		transform: translate3d(0, 0, 0)

	&.available:hover &,
	&.available:focus &
		&Icon
			transform: translate3d(0, 0, 0) scale(1.1)

	&:first-child:not(:last-child)
		margin-right: 2 * $gap

		&::after
			content: ''
			pointer-events: none
			position: absolute
			top: 0
			right: -$gap
			bottom: 0
			width: 1px
			background-color: #dbe5ef

.authors
	&Avatars
		font-size: .75em
		display: flex
		align-items: center
		justify-content: center
		flex-wrap: wrap
		margin-bottom: 1em

		.authorAvatar
			margin: 0

	&Avatar
		&Sep
			font-size: 4em
			padding: .125em
			flex-grow: 0
			opacity: .2

.Card
	&Payment
		position: relative
		margin: 1em 0
		border-radius: 1.25em
		border: solid 1px common.$c-white
		background: lighten(common.$c-blue-light, 4%)
		box-shadow: 0 0.05em .1em rgba(common.$c-dark, .2),0 0 .8em rgba(common.$c-dark, .2)

		button
			white-space: nowrap

	&Element
		margin: .5em
		margin-bottom: 0
		padding: .5em
		border: solid 1px #AAB7C4
		border-radius: .66em

	&Error
		padding: 0.75em
		padding-bottom: 0

	&Button
		padding: 0.75em
